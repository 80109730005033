import React, { Component } from 'react';

export default class UserNotFound extends Component {

  render() {
    return (
      <div className="mt-4">
        <div className="text-center">
          <p className="lead">
            <i className="fas fa-exclamation-triangle text-warning"/> The user you are looking for could not be
            found.
          </p>
        </div>
      </div>
    );
  }
}
