import React  from 'react';
import PropTypes from 'prop-types';

import TextField from '../../General/TextField';
import Button from '../../General/Button';
import Checkbox from '../../General/Checkbox';

export default class SystemConfigurationForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showUserExpiration: props.settings.enableUserExpiration || false
    };
  };

  static propTypes = {
    settings: PropTypes.object,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submittingForm: PropTypes.bool,
    cancel: PropTypes.func,
  };

  onSubmit = (ev) => {
    ev.preventDefault();
    this.props.handleSubmit();
  };

  /**
   * Handle the checkbox change (enable user expiration)
   * @param obj
   */
  handleCheckboxChange = (obj) => {
    const { handleChange } = this.props;
    handleChange(obj);
  };

  render() {
    const { settings, handleChange, submittingForm, cancel } = this.props;

    const defaultClasses = {
      wrapperClass: 'form-group row',
      labelClass: 'col-4 col-sm-4 col-md-4 col-form-label text-right',
      inputClass: 'form-control',
      inputWrapperClass: 'col-6 col-sm-7 col-md-6 col-lg-5 col-xl-4'
    };

    const helpText = {
      bucketName: 'This default upload bucket is used if a user’s upload bucket name is blank.', //TODO: Add a bucket name example and the default value
      sharedPath: 'This shared S3 path is used as a common download folder across users. ' +
      'You can enable or disable access to the shared folder for each user.', //TODO: ADD an example for what a shared path would look like
      enableUserExpiration: 'Enables the ability to expire users and delete all of their uploaded data. User expiration is irreversible, proceed with caution.',
      userExpirationDays: 'This default number of days is used to determine when to expire users after their creation. ' +
          'If the field is left blank, users will expire based on their individual expiration schedules.'
    };

    return (
      <form className='system-config-form' onSubmit={this.onSubmit}>

        <TextField {...defaultClasses} label={['Default Upload S3', <br key='1' />, 'Bucket Name']} name='bucketName'
                   onInputChange={handleChange} value={settings === undefined ? '' : settings.bucketName}
                   underFieldHelpText={helpText.bucketName} required/>

        <TextField {...defaultClasses} label='Shared S3 Path' name='sharedPath'
                   onInputChange={handleChange} value={settings === undefined ? '' : settings.sharedPath}
                   underFieldHelpText={helpText.sharedPath} required/>

        {this.state.showUserExpiration &&
          <>
            <Checkbox {...defaultClasses} label='Enable User Expiration' name='enableUserExpiration'
                    onInputChange={this.handleCheckboxChange}
                    checked={(settings === undefined || settings.enableUserExpiration === undefined) || settings.enableUserExpiration === null ? false : settings.enableUserExpiration}
                    helpText={helpText.enableUserExpiration} />

            <TextField {...defaultClasses} type='number' label='Default User Expiration Days' name='userExpirationDays'
              onInputChange={handleChange}
              value={settings === undefined || settings.userExpirationDays === null || settings.userExpirationDays === undefined ||
              settings.enableUserExpiration === undefined || !settings.enableUserExpiration  ? '' : settings.userExpirationDays}
              underFieldHelpText={helpText.userExpirationDays}
              disabled={settings === undefined || settings.enableUserExpiration === undefined || settings.enableUserExpiration === null || !settings.enableUserExpiration} />
          </>
        }

        <div className='default-folders'>
          <Checkbox {...defaultClasses} label="Default User Directories" name='enableLocalDir' checkboxLabel='local' onInputChange={this.handleCheckboxChange}
                    checked={(settings === undefined || settings.enableLocalDir === undefined) || settings.enableLocalDir === null ? false : settings.enableLocalDir} />

          <Checkbox {...defaultClasses} name='enableUploadDir' checkboxLabel='uploads' onInputChange={this.handleCheckboxChange}
                    checked={(settings === undefined || settings.enableUploadDir === undefined) || settings.enableUploadDir === null ? false : settings.enableUploadDir} />

          <Checkbox {...defaultClasses} name='enableDownloadDir' checkboxLabel='downloads' onInputChange={this.handleCheckboxChange}
                    checked={(settings === undefined || settings.enableDownloadDir === undefined) || settings.enableDownloadDir === null ? false : settings.enableDownloadDir} />

          <Checkbox {...defaultClasses} name='enableSharedDir' checkboxLabel='shared' onInputChange={this.handleCheckboxChange}
                    checked={(settings === undefined || settings.enableSharedDir === undefined) || settings.enableSharedDir === null ? false : settings.enableSharedDir} />
        </div>

        <section className='settings-form-buttons'>
          <Button type='submit' label={submittingForm ? [<i key='1' className='fa fa-spinner fa-spin'></i>, ' Apply'] :
            [<i key='1' className='fas fa-check'></i>, ' Apply']}
                  wrapperClass=''
                  buttonClass=' sftpgateway-blue-btn' disabled={submittingForm}/>
          <Button type='button' label={[<i key='1' className='fas fa-times'></i>, ' Cancel']}
                  buttonClass='sftpgateway-light-btn ml-2' onClick={cancel}/>
        </section>
      </form>
    );
  };
}
