function handleErrorMessage(error) {
  if (error.response !== undefined) {

    // Catch all special errors for which you'd like to create custom text.
    if (error.response.data !== undefined) {
      if (error.response.data.message !== undefined) {
        let message = error.response.data.message;
        if (message.toLowerCase().indexOf("error code 68") >= 0) {
          return { msg: 'Attempted to add an entry that already exists.', type: 'warn' };
        } else if (message.toLowerCase().indexOf("error code 21") >= 0
          || message.toLowerCase().indexOf("error code 16") >= 0) {
          let msg = message.substring(message.indexOf('[') + 1, message.indexOf(']'));
          return { msg, type: 'warn' };
        } else if (message.toLowerCase().indexOf("error code 49") >= 0) {
          return { msg: 'Invalid LDAP credentials.', type: 'error' };
        } else if (message.toLowerCase().indexOf("error code 32") >= 0) {
          return { msg: 'No such entry exists.', type: 'error' };
        } else if (message.toLowerCase().indexOf("service: awssimplesystemsmanagement") >= 0) {
          return { msg: message, type: 'warn' };
        } else if (message.toLowerCase().indexOf('(service: amazon s3') >= 0) {
          let msg = message.substring(0, message.indexOf('(Service: Amazon S3;'));
          return { msg: "Amazon S3: " + msg, type: 'error' };
        }
      }
    }

    switch (error.response.status) {
      case 403:
        return { msg: 'Please Login!', type: 'info' };

      case 400:
        if (error.response.data !== undefined && error.response.data.errors !== undefined && error.response.data.errors.length > 0) {
          if (error.response.data.errors[0].defaultMessage !== undefined) {
            return { msg: error.response.data.errors[0].defaultMessage, type: 'warn' };
          }
        }
        if (error.response.data !== undefined && error.response.data.message !== undefined) {
          return { msg: error.response.data.message, type: 'error' };
        }
        break;

      case 500:
        if (error.response.data !== undefined && error.response.data.message !== undefined) {
          let message = error.response.data.message;
          if (message.toLowerCase().indexOf("sftpgatewaysetup") >= 0) {
            return { msg: 'Could not run sftpgatewaysetup script.', type: 'error' };
          } else if (message.toLowerCase().indexOf("usersetup") >= 0) {
            return { msg: 'Could not run usersetup script.', type: 'error' };
          } else if (message.toLowerCase().indexOf("sftpgateway.properties") >= 0) {
            return { msg: 'Could not find sftpgateway.properties file.', type: 'error' };
          }
          return { msg: error.response.data.message, type: 'error' };
        } else {
          return { msg: "Server error!", type: "error" };
        }

      default:
        break;
    }
  }
  return { msg: 'Network error occurred. Please try again or contact support.', type: 'error' };
}

export default handleErrorMessage;
