import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../components/General/Modal';
import utils from '../../../components/General/Modal/Utils';

export default class EditUserModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      messageCopied: false,
      showPasswordText: 'Show',
      showPassword: false,
      password: "*********"
    };
  };

  static propTypes = {
    hideModal: PropTypes.func,
    user: PropTypes.object,
    sshKeyOption: PropTypes.string,
    passwordOption: PropTypes.string
  };

  /**
   * Copy to clipboard the message to send to the user after the create.
   */
  copyMessage = () => {

    const { user, sshKeyOption, passwordOption } = this.props;

    let intro = "Hi Friend,\n" +
      "\n" +
      "I know, I know, change is hard. But I just made life easier by changing your SFTP Gateway profile for you!\n" +
      "\n";
    let sshKeyStr = "";
    let passwordStr = "";

    if (sshKeyOption === "generate" || sshKeyOption === "upload") {
      sshKeyStr = "To use SSH key authentication:\n" +
        "Please see the attached file \"" + user.username + ".key\".\n" +
        "\n" +
        "- Download the file and change its permissions:\n" +
        "chmod 600 " + user.username + ".key\n" +
        "- Connect to SFTP Gateway:\n" +
        "sftp -i " + user.username + ".key " + user.username + "@" + window.location.host + "\n" +
        "- Change your directory:\n" +
        "sftp> cd uploads\n" +
        "- Upload a file:\n" +
        "sftp> put text.txt\n" +
        "\n";
    }

    if (passwordOption === "password") {
      passwordStr = "To use password authentication:\n" +
        "- Connect to SFTP Gateway:\n" +
        "sftp " + user.username + "@" + window.location.host + "\n" +
        "- Enter password:\n" +
        user.username + "@" + window.location.host + "'s password: " + user.password + "\n" +
        "- Change your directory:\n" +
        "sftp> cd uploads\n" +
        "- Upload a file:\n" +
        "sftp> put text.txt\n" +
        "\n";
    }

    let conclusion = "If you have any questions, just let me know. Thanks!";

    utils.copyToClipboard(intro + sshKeyStr + passwordStr + conclusion);
    this.setState({ messageCopied: true });
  };

  togglePassword = () => {
    const { user } = this.props;
    let showPassword = !this.state.showPassword;
    let showPasswordText = showPassword ? "Hide" : "Show";
    let password = showPassword ? user.password : "*********";
    this.setState({ showPassword, showPasswordText, password });
  };

  render() {

    const { hideModal, user, sshKeyOption, passwordOption } = this.props;
    const { messageCopied, showPasswordText, password } = this.state;

    return (
      <Modal onClose={hideModal}>
        <p>Changes saved!</p>

        {sshKeyOption === "generate" &&
        <p>
          An SSH private key file should have been downloaded to your computer.
          You'll need to email this file to the user you just edited.
        </p>
        }
        {passwordOption === "password" &&
        <p>This is the last time the password will be available to copy. However, you can change the password at any
          time.</p>
        }
        <p>
          You can copy and paste the text below into your email to this user.
          Or just come up with your own. Whatever you want to do!
        </p>
        <p>
          Enjoy!
        </p>

        <hr/>

        <p>Hi Friend, </p>
        <p>
          I know, I know, change is hard. But I just made life easier by changing your SFTP Gateway profile for you!
        </p>
        {(sshKeyOption === "generate" || sshKeyOption === "upload") &&
        <p>
          Please see the attached file "{user.username}.key".
        </p>
        }
        {(sshKeyOption === "generate" || sshKeyOption === "upload") &&
        <p>
          <u>To use SSH key authentication</u>:
          <br/>
          - Download the file and change its permissions:
          <br/>
          <code>chmod 600 {user.username}.key</code>
          <br/>
          - Connect to SFTP Gateway:
          <br/>
          <code>sftp -i {user.username}.key {user.username}@{window.location.host}</code>
          <br/>
          - Change your directory:
          <br/>
          <code>sftp> cd uploads</code>
          <br/>
          - Upload a file:
          <br/>
          <code>sftp> put text.txt</code>
        </p>
        }

        {passwordOption === "password" &&
        <p>
          <u>To use password authentication</u>:
          <br/>
          - Connect to SFTP Gateway:
          <br/>
          <code>sftp {user.username}@{window.location.host}</code>
          <br/>
          - Enter password:
          <br/>
          <code>{user.username}@{window.location.host}'s password: {password} <span
            className='show-password text-primary cursor-pointer'
            onClick={this.togglePassword}>{showPasswordText}</span></code>
          <br/>
          - Change your directory:
          <br/>
          <code>sftp> cd uploads</code>
          <br/>
          - Upload a file:
          <br/>
          <code>sftp> put text.txt</code>
        </p>
        }
        <p>
          If you have any questions, just let me know. Thanks!
        </p>
        <section className="sftpgateway-modal-buttons">
          <button className="sftpgateway-blue-btn" onClick={this.copyMessage}>{messageCopied ?
            [<i key="1" className="far fa-thumbs-up"></i>, ' Text copied'] : [<i key="1"
                                                                                 className="far fa-copy"></i>, ' Copy Text']}
          </button>
          <button className="sftpgateway-light-btn ml-2" onClick={hideModal}>Done</button>
        </section>
      </Modal>
    );
  };
}
