import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link, Prompt, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import routes from '../../../routes';
import api_routes from '../../../api_routes';
import UserForm from '../../../components/User/Form';
import AzureUserForm from '../../../components/User/AzureForm';
import Spinner from '../../../components/General/Spinner';
import utils from '../../../components/General/Modal/Utils';
import handleErrorMessage from '../../../errors';
import CreateUserModal from './modal';

const CLOUD_PROVIDER = process.env.REACT_APP_CLOUD_PROVIDER;

export default class UserCreateContainer extends Component {

  constructor(props) {
    super(props);
    this.createUser = this.createUser.bind(this);
    this.state = {
      user: {
        username: '',
        password: '',
        bucketName: '',
        path: '',
        pubSsh: '',
        s3EncryptionLevel: '',
        expirationDate: '',
        enableLocalDir: false,
        enableUploadDir: false,
        enableSharedDir: false,
        enableDownloadDir: false
      },
      sshKeyOption: 'generate',
      passwordOption: 'noPassword',
      isBlocking: false,
      redirect: false,
      creatingUser: false,
      showModal: false,
    };
  };

  static propTypes = {
    loading: PropTypes.bool,
    s3Encryption: PropTypes.array, // Types of ssh encryption.
    settings: PropTypes.object
  };

  static getDerivedStateFromProps(props, state) {
    if (!state.isBlocking) {
      let { user } = state;
      if (props.settings.enableUserExpiration !== null && props.settings.enableUserExpiration !== undefined
          && props.settings.enableUserExpiration
          && props.settings.userExpirationDays !== null && props.settings.userExpirationDays !== undefined &&
          props.settings.userExpirationDays !== '') {
        user = { ...user, expirationDate: moment().add(props.settings.userExpirationDays, 'days').format('YYYY-MM-DD') };
      }
      if (props.settings.enableLocalDir !== null && props.settings.enableLocalDir !== undefined) {
        user = { ...user, enableLocalDir: props.settings.enableLocalDir };
      }
      if (props.settings.enableUploadDir !== null && props.settings.enableUploadDir !== undefined) {
        user = { ...user, enableUploadDir: props.settings.enableUploadDir };
      }
      if (props.settings.enableSharedDir !== null && props.settings.enableSharedDir !== undefined) {
        user = { ...user, enableSharedDir: props.settings.enableSharedDir };
      }
      if (props.settings.enableDownloadDir !== null && props.settings.enableDownloadDir !== undefined) {
        user = { ...user, enableDownloadDir: props.settings.enableDownloadDir };
      }
      return {...state, user: {...user}};
    }
    return null;
  }

  /**
   * Change ssh key option.
   * @param value
   */
  changeSshKeyOption = (value) => {
    this.setState({ sshKeyOption: value, isBlocking: true });
  };

  /**
   * Change password option.
   * @param value
   */
  changePasswordOption = (value) => {
    this.setState({ passwordOption: value, isBlocking: true });
  };

  /**
   * Close the modal and redirect to the users page.
   */
  hideModal = () => {
    this.setState({ showModal: false, redirect: true });
  };

  /**
   * Updates the user information.
   * @param obj
   */
  handleChange = (obj) => {
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        [Object.keys((obj))[0]]: Object.values((obj))[0]
      },
      isBlocking: true,
    }));
  };

  /**
   * Cancel creating a user.
   */
  cancel = () => {
    if (this.state.isBlocking) {
      if (window.confirm('Are you sure you don’t want to create this user? Your changes will not be saved.')) {
        this.setState({ redirect: true });
      }
    } else {
      this.setState({ redirect: true });
    }
  };

  /**
   * Save the user.
   */
  async createUser() {

    this.setState({ creatingUser: true });

    const { user, sshKeyOption, passwordOption } = this.state;

    let keys;
    // Check if the ssh key option is to upload. Let the user know if they didn't upload the key.
    if (sshKeyOption === 'upload' && user.pubSsh === '') {
      this.props.notify({ type: 'warn', msg: 'Don\'t forget to upload the public key.' });
      this.setState({ creatingUser: false });
      return;
    } else if (sshKeyOption === 'generate') { // If the user chose to generate the keys
      keys = await this.props.generateKeys();             // Generate the keys
      this.handleChange({ pubSsh: keys.data.publicKey }); // Associate the public key with the user in the state.
    }

    // Check if the user wants to use a password to login but forgot to either generate one or provide their own.
    if (passwordOption === 'password' && user.password === '') {
      this.props.notify({ type: 'warn', msg: 'Don\'t forget to provide a password.' });
      this.setState({ creatingUser: false });
      return;
    }

    // Check if both noKey and noPassword options were chosen for login.
    if (passwordOption === 'noPassword' && sshKeyOption === 'noKey') {
      this.props.notify({ type: 'warn', msg: 'Please choose the login option.' });
      this.setState({ creatingUser: false });
      return;
    }

    // Create user
    axios.post(api_routes.users.endpoint, this.state.user)
      .then(() => { // If success

        // Trigger private key download if the user chose to generate the key.
        if (sshKeyOption === 'generate') {
          utils.download(user.username, keys.data.privateKey);
        }
        // Update the state.
        this.setState({ isBlocking: false, creatingUser: false, showModal: true });
        // Update the list of users.
        this.props.updateUsers();
      })
      .catch((error) => { // If failure

        // Notify the user about the failure
        this.props.notify(handleErrorMessage(error));
        this.setState({ creatingUser: false });
        // Logout if error status is 403
        if (error.response !== undefined && error.response.status === 403) {
          this.props.logout();
        }
      });
  };

  userForm = (s3Encryption, user, sshKeyOption, passwordOption, creatingUser, settings, rest) => {
    if (CLOUD_PROVIDER === 'azure') {
      return (
        <AzureUserForm {...rest} handleChange={this.handleChange} handleSubmit={this.createUser}
                  s3Encryption={s3Encryption} user={user} sshKeyOption={sshKeyOption} settings={settings}
                  submittingForm={creatingUser} changeSshKeyOption={this.changeSshKeyOption} cancel={this.cancel}
                  passwordOption={passwordOption} changePasswordOption={this.changePasswordOption} password={user.password} />
      );
    }
    if (CLOUD_PROVIDER === 'aws') {
      return (
        <UserForm {...rest} handleChange={this.handleChange} handleSubmit={this.createUser}
                  s3Encryption={s3Encryption} user={user} sshKeyOption={sshKeyOption} settings={settings}
                  submittingForm={creatingUser} changeSshKeyOption={this.changeSshKeyOption} cancel={this.cancel}
                  passwordOption={passwordOption} changePasswordOption={this.changePasswordOption} password={user.password} />
      );
    }
  };

  render() {
    const { user, sshKeyOption, redirect, isBlocking, creatingUser, showModal, passwordOption } = this.state;
    const { loading, s3Encryption, settings, ...rest } = this.props;

    if (redirect) {
      return (
        <Redirect to={routes.users.path}/>
      );
    }

    return (
      <div className='mt-4'>
        <div className='nav-breadcrumb'>
          <Link to={routes.users.path}>
            <i title='Go back' className='fas fa-long-arrow-alt-left text-primary'></i> <span className='text-primary'>Back to Users</span>
          </Link>
        </div>
        <div className='text-center'>
          <h2>Create User</h2>
        </div>
        <div className='mt-4'>
          {!loading ? (
            this.userForm(s3Encryption, user, sshKeyOption, passwordOption, creatingUser, settings, rest)
          ) : (
            <div className='text-center mt-5'><Spinner spin/></div>
          )}
        </div>
        <Prompt
          when={isBlocking}
          message={location =>
            'You have unsaved changes. Are you sure you want to leave this page?'
          }
        />
        {showModal &&
          <CreateUserModal hideModal={this.hideModal} user={user} sshKeyOption={sshKeyOption} passwordOption={passwordOption} />
        }
      </div>
    );
  };
}
