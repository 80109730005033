import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import axios from 'axios';

import routes from '../routes';
import handleErrorMessage from '../errors';
import api_routes from '../api_routes';
import UserListContainer from './User/List';
import UserEditContainer from './User/Edit';
import UserCreateContainer from './User/Create';
import Spinner from '../components/General/Spinner';
import SystemConfigEditContainer from './SystemConfig/Edit';
import Navigation from '../components/General/Navigation';
import PageNotFound from '../components/General/PageNotFound';
import withNotification from '../components.higher.order/withNotifications';
import withAuthentication from '../components.higher.order/withAuthentication';
import Footer from "../components/General/Footer";
import DiagnosticsDetailsContainer from "./Diagnostics/Details";

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      users: [],
      s3Encryption: [],
      config: {
        bucketName: '',
        sharedPath: '',
        enableUserExpiration: false,
        userExpirationDays: 0,
        enableLocalDir: false,
        enableUploadDir: false,
        enableDownloadDir: false,
        enableSharedDir: false
      },
      loadingUsers: true,
      loadingConfig: true,
      loadingS3Encryption: true
    };
  }

  componentDidMount() {
    this.getUsers();
    this.getS3Encryption();
    this.getSettings();
  }

  getUsers = () => {
    this.setState({ loadingUsers: true });
    axios.get(api_routes.users.endpoint)
    .then(response => {
      this.setState({ users: response.data.users, loadingUsers: false });
    })
    .catch(error => {
      this.props.notify(handleErrorMessage(error));
      if (error.response !== undefined && error.response.status === 403) {
        this.props.logout();
      }
    });
  };

  getS3Encryption = () => {
    axios.get(api_routes.s3Encryption.endpoint)
    .then(response => {
      this.setState({ s3Encryption: response.data.encryptionLevels, loadingS3Encryption: false });
    });
  };

  getS3EncryptionAsyncAwait = () => {
    return axios.get(api_routes.s3Encryption.endpoint)
    .then(response => {
      this.setState({ s3Encryption: response.data.encryptionLevels });
    })
    .catch((error) => {
      this.props.notify(handleErrorMessage(error));
    });
  };

  getSettings = () => {
    this.setState({ loadingConfig: true });
    axios.get(api_routes.settings.endpoint)
    .then(response => {
      this.setState({ config: response.data, loadingConfig: false });
    })
    .catch((error) => {
      this.props.notify(handleErrorMessage(error));
    });
  };

  deleteUser = ({ username = '' }) => {
    this.setState({ loadingUsers: true });
    this.deleteUserPromise({ username })
    .then(() => {
      this.props.notify({ msg: `User ${username} has been deleted.`, type: 'success' });
      this.getUsers();
    })
    .catch((error) => {
      this.props.notify(handleErrorMessage(error));
      if (error.response !== undefined && error.response.status === 403) {
        this.props.logout();
      }
      if (error.response !== undefined && error.response.status === 404) {
        this.getUsers();
      }
    });
  };

  deleteUserPromise = ({ username = '' }) => {
    return axios.delete(`${api_routes.users.endpoint}/${username}`);
  };

  generateKeysAsyncAwait = () => {
    return axios.get(api_routes.generateKeys.endpoint);
  };

  render() {
    const { users, s3Encryption, config, loadingUsers, loadingConfig, loadingS3Encryption } = this.state;

    return (
      <Router>
        <div className="h-100">
          <div className="container content-container main-wrapper">
            <Navigation logout={this.props.logout} notify={this.props.notify}/>
            <Switch>
              <Route exact path={routes.users.path} render={() => (
                <UserListContainer users={users} deleteUser={this.deleteUser} updateUsers={this.getUsers}
                                   config={config} loading={loadingUsers || loadingConfig}
                                   notify={this.props.notify}/>
              )}/>
              <Route exact path={`${routes.users.path}/new`} render={() => (
                <UserCreateContainer s3Encryption={s3Encryption} notify={this.props.notify} logout={this.props.logout}
                                     updateUsers={this.getUsers} updateS3Encryption={this.getS3EncryptionAsyncAwait}
                                     generateKeys={this.generateKeysAsyncAwait} settings={config}
                                     loading={loadingS3Encryption && loadingConfig}/>
              )}/>
              <Route path={`${routes.users.path}/:username/edit`} render={({ match }) => (
                <div>
                  {!loadingUsers && !loadingS3Encryption && !loadingConfig ? (
                    <UserEditContainer user={users.find(user => user.username === match.params.username) || {}}
                                       s3Encryption={s3Encryption} settings={config} notify={this.props.notify}
                                       updateUsers={this.getUsers} generateKeys={this.generateKeysAsyncAwait}
                                       updateS3Encryption={this.getS3EncryptionAsyncAwait}
                                       deleteUser={this.deleteUserPromise} logout={this.props.logout}/>
                  ) : (
                    <div className="text-center mt-5"><Spinner spin/></div>
                  )}
                </div>
              )}/>
              <Route exact path={routes.settings.path} render={() => (
                <div>
                  {!loadingConfig ? (
                    <SystemConfigEditContainer notify={this.props.notify} config={config}
                                               updateConfig={this.getSettings}
                                               logout={this.props.logout}/>
                  ) : (
                    <div className="text-center mt-5"><Spinner spin/></div>
                  )}
                </div>
              )}/>
              <Route exact path={routes.diagnostics.path} render={() => (
                <div>
                  {!loadingConfig ? (
                    <DiagnosticsDetailsContainer notify={this.props.notify}
                                                 logout={this.props.logout}/>
                  ) : (
                    <div className="text-center mt-5"><Spinner spin/></div>
                  )}
                </div>
              )}/>
              <Redirect from={routes.azureTokenCallback.path} to={routes.users.path}/>
              <Redirect from={routes.login.path} to={routes.users.path}/>
              <Redirect exact from="/" to={routes.users.path}/>
              <Route component={PageNotFound}/>
            </Switch>
            <Footer/>
          </div>
        </div>
      </Router>
    );
  }
}

export default withNotification(withAuthentication(App));
