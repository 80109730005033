import React  from 'react';
import PropTypes from 'prop-types';

export default class TextField extends React.Component {

  static propTypes = {
    wrapperClass: PropTypes.string,
    type: PropTypes.string || 'text',
    name: PropTypes.string.isRequired,
    inputClass: PropTypes.string,
    inputWrapperClass: PropTypes.string,
    labelClass: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.any || '',
    onInputChange: PropTypes.func.isRequired,
    label: PropTypes.any,
    disabled: PropTypes.bool,
    helpText: PropTypes.string,
    underFieldHelpText: PropTypes.any,
    required: PropTypes.bool,
    sideIcon: PropTypes.string,
    onSideIconClick: PropTypes.func,
    min: PropTypes.any
  };

  handleInputChange = (ev) => {
    this.props.onInputChange({ [this.props.name]: ev.target.value });
  };

  render() {
    const { wrapperClass, type, name, inputClass, inputWrapperClass, labelClass, placeholder, value, label, disabled,
      helpText, required, underFieldHelpText, sideIcon, onSideIconClick, min } = this.props;
    return (
      <div className={`${wrapperClass}${required ? ' required' : ''}`}>
        {label &&
          <label className={labelClass}>{label}</label>
        }
        {helpText &&
        <span className="custom-tooltip" data-tooltip={helpText}><i className="fas fa-question-circle icon"/></span>
        }
        <div className={`input ${inputWrapperClass}`}>
          <input
            type={type}
            name={name}
            className={inputClass}
            placeholder={placeholder}
            value={value}
            onChange={this.handleInputChange}
            disabled={disabled}
            required={required}
            min={min}
          />
          {underFieldHelpText &&
          <div className="checkbox-help-text" style={{ marginBottom: "1em" }}>
            {underFieldHelpText}
          </div>
          }
          {sideIcon &&
          <i className={`${sideIcon} cursor-pointer icon`} onClick={onSideIconClick}></i>
          }
        </div>
      </div>
    );
  };
}
