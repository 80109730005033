import axios from "axios";
import AzureIntegration from "../components/Login/AzureIntegration";

const authToken = 'sftpg-auth';

const TOKEN_TYPE = {
  sftpgw: "sftpgw",
  azure: "azure",
};

function tryParseJSON(jsonString) {
  try {
    const o = JSON.parse(jsonString);
    //https://stackoverflow.com/a/20392392/1368038
    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === "object") {
      return o;
    }
  } catch (e) {
  }

  return {};
}

function getTokenObj() {
  return tryParseJSON(localStorage.getItem(authToken));
}

export default class AuthToken {
  static TOKEN_TYPE = TOKEN_TYPE;

  static saveAuthToken = (token, type = TOKEN_TYPE.sftpgw) => {
    if (!!token && !token.startsWith('Bearer ')) {
      token = `Bearer ${token}`;
    }
    const tokenObj = {
      token,
      type
    };
    localStorage.setItem(authToken, JSON.stringify(tokenObj));
    axios.defaults.headers.common['Authorization'] = token;
  };

  static getTokenType = () => {
    // The "|| {}" piece makes sure that we are not getting a property on a null object
    return getTokenObj().type;
  };

  static getToken = () => {
    // The "|| {}" piece makes sure that we are not getting a property on a null object
    return getTokenObj().token;
  };

  static hasToken = () => {
    return !!localStorage.getItem(authToken);
  };

  static logout = () => {
    AuthToken.clearToken();
  };

  static getPostLogoutRedirect = () => {
    if (AuthToken.getTokenType() === TOKEN_TYPE.azure) {
      return AzureIntegration.getLogoutUrl();
    }
  };

  static clearToken = () => {
    AuthToken.saveAuthToken("", AuthToken.getTokenType());
  };

}