import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import axios from 'axios';
import queryString from 'query-string';
import handleErrorMessage from '../../errors';
import AuthToken from 'util/AuthToken';
import api_routes from '../../api_routes';
import routes from '../../routes';
import LoginContainer from '../../containers/Login';
import ResetPasswordModal from "./resetPasswordModal";

const TOKEN_TYPE = AuthToken.TOKEN_TYPE;

function withAuthentication(WrappedComponent) {
  return class extends Component {

    constructor(props) {
      super(props);
      this.state = {
        authenticated: false,
        authenticationCheck: false,
        authenticating: false,
        passwordResetRequested: false,
        showResetPassword: true,
      };
    }

    componentDidMount() {
      // Process azure callback
      if (window.location.pathname === routes.azureTokenCallback.path) {
        this.processAzureCallback(window.location);
      }
      this.isAuthenticated();
    }

    notify = (obj) => {
      if (this.props.notify !== undefined) {
        this.props.notify(obj);
      }
    };

    login = (creds) => {
      this.setState({ authenticating: true });
      axios.post(api_routes.login.endpoint, creds)
      .then((response) => {
        const { authorization } = response.headers;
        AuthToken.saveAuthToken(authorization);
        this.setState(() => ({ authenticated: true, authenticating: false }));
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.status === 403) {
          this.handleAuthError('You have entered an invalid username or password');
        } else {
          this.handleAuthError(handleErrorMessage(error));
        }
        this.setState({ authenticated: false, authenticating: false });
      });
    };

    processAzureCallback = (location) => {
      const params = queryString.parse(location.hash);
      if (params.id_token) {
        AuthToken.saveAuthToken(params.id_token, TOKEN_TYPE.azure);
      } else if (params.error_description) {
        this.handleAuthError(params.error_description);
      }
    };

    handleAuthError = (error_description) => {
      this.notify({ msg: decodeURIComponent(error_description), type: 'error' });
    };

    /**
     * Ping the server.
     */
    pingServer = () => {
      return axios.get(api_routes.ping.endpoint);
    };

    /**
     * Check if the password reset has been requested.
     */
    checkPasswordResetRequest = () => {
      return axios.get(api_routes.passwordResetRequest.endpoint);
    };

    /**
     * If pinging the server returns 403, keep authenticated as false.
     */
    isAuthenticated = () => {
      // First check if the password needs to be reset.
      this.checkPasswordResetRequest()
      .then((response) => {
        if (this.state.showResetPassword) {
          let resetFlag = response.data.toLowerCase() === 'true';
          this.setState({ passwordResetRequested: resetFlag });
          return resetFlag;
        } else {
          return false;
        }
      })
      .then((resetFlag) => {
        // If the password doesn't need to be reset proceed to the authentication flow.
        if (resetFlag === false) {
          const token = AuthToken.getToken();
          if (!!token) {
            // Add token to the header: Authorization: <token>
            axios.defaults.headers.common['Authorization'] = token;
            this.pingServer()
            .then(() => {
              this.setState({ authenticationCheck: true, authenticated: true });
            })
            .catch((error) => {
              this.setState({ authenticationCheck: true });
              if (error.response === undefined) {
                this.notify(handleErrorMessage(error));
              }
            });
          } else {
            this.setState({ authenticationCheck: true });
          }
        }
      })
      .catch((error) => {
        this.notify(handleErrorMessage(error));
      });
    };

    logout = (e) => {
      AuthToken.logout();
      if (AuthToken.getPostLogoutRedirect()) {
        e && e.preventDefault();
        window.location.replace(AuthToken.getPostLogoutRedirect());
        return false;
      }
    };

    /**
     * Close the ResetPasswordModal.
     */
    hideModal = () => {
      this.setState({ showResetPassword: false, passwordResetRequested: false });
      this.isAuthenticated();
    };

    render() {
      const { authenticated, authenticationCheck, authenticating, passwordResetRequested } = this.state;

      // Display a modal with instructions to reset password if password reset had been requested.
      if (passwordResetRequested) {
        return <ResetPasswordModal hideModal={this.hideModal} />
      }

      // Do not display anything until you contact the server and figure out whether you are authenticated or not.
      if (!authenticationCheck) {
        return null;
      }

      if (authenticated) {
        return (<WrappedComponent {...this.props} logout={this.logout}/>);
      }

      return (
        <Router>
          <Switch>
            <Route exact path={routes.login.path} render={() => (
              <LoginContainer notify={this.props.notify} login={this.login} authenticating={authenticating}/>
            )}/>
            <Route>
              <Redirect to={routes.login.path}/>
            </Route>
          </Switch>
        </Router>
      );
    }
  }
}

export default withAuthentication;
