import routes from '../../../routes';

export default {
  isAzureIntegrationEnabled() {
    return !!window.adalConfig;
  },

  initializeAzureADIntegration() {
    //Configure Azure AD Integration
    //Check if environment variables have values if config values are not in azure_ad_config.js
    if (!this.isAzureIntegrationEnabled()) {
      if (process.env.REACT_APP_AZURE_CLIENT_ID && process.env.REACT_APP_AZURE_TENANT_ID) {
        window.adalConfig = {
          clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
          tenant: process.env.REACT_APP_AZURE_TENANT_ID
        };
      }
    }
  },

  getLoginUrl() {
    if (this.isAzureIntegrationEnabled()) {
      const state = Math.ceil(Math.random() * 100000);
      const nonce = Math.ceil(Math.random() * 100000);
      const absoluteRedirectUrl = `${window.location.origin}${routes.azureTokenCallback.path}`;
      return `https://login.microsoftonline.com/${window.adalConfig.tenant}` +
        `/oauth2/v2.0/authorize?client_id=${window.adalConfig.clientId}&response_type=id_token` +
        `&redirect_uri=${encodeURIComponent(absoluteRedirectUrl)}&scope=openid&response_mode=fragment` +
        `&state=${state}&nonce=${nonce}`;
    }
  },

  getLogoutUrl() {
    if (this.isAzureIntegrationEnabled()) {
      const absoluteRedirectUrl = `${window.location.origin}`;
      const tenant = window.adalConfig.tenant;

      // return `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/oauth2/v2.0/logout?`
      //   + `post_logout_redirect_uri=${encodeURIComponent(absoluteRedirectUrl)}`;
      return `https://login.microsoftonline.com/${tenant}/oauth2/v2.0/logout?`
        + `post_logout_redirect_uri=${encodeURIComponent(absoluteRedirectUrl)}`;
    }
  },

}
