import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, Prompt, Redirect } from "react-router-dom";
import axios from 'axios';

import routes from '../../../routes';
import api_routes from '../../../api_routes';
import UserForm from '../../../components/User/Form';
import AzureUserForm from '../../../components/User/AzureForm';
import UserNotFound from '../../../components/User/UserNotFound';
import handleErrorMessage from "../../../errors";
import utils from "../../../components/General/Modal/Utils";
import EditUserModal from "./modal";

const CLOUD_PROVIDER = process.env.REACT_APP_CLOUD_PROVIDER;

export default class UserEditContainer extends Component {

  constructor(props) {
    super(props);
    this.updateUser = this.updateUser.bind(this);
    this.state = {
      user: props.user,
      sshKeyOption: props.user.pubSsh !== null && props.user.pubSsh !== '' && props.user.pubSsh !== undefined ? 'keep' : 'noKey',
      originalSshKey: props.user.pubSsh,
      passwordOption: props.user.usesPassword !== undefined && props.user.usesPassword.toString().toLowerCase() === 'true' ? 'keep': 'noPassword',
      isBlocking: false,
      redirect: false,
      updatingUser: false,
      showModal: false,
      updatedPassword: ''
    };
  };

  static propTypes = {
    deleteUser: PropTypes.func.isRequired,
    s3Encryption: PropTypes.array,
    user: PropTypes.object,
    settings: PropTypes.object
  };

  /**
   * Change ssh key option.
   * @param value
   */
  changeSshKeyOption = (value) => {
    this.setState({ sshKeyOption: value, isBlocking: true });
    if (value === 'upload') {
      this.handleChange({ pubSsh: '' });
    } else if (value === 'keep') {
      this.handleChange({ pubSsh: this.props.user.pubSsh });
    } else if (value === 'noKey') {
      this.handleChange({ pubSsh: '' });
    }
  };

  /**
   * Change password option.
   * @param value
   */
  changePasswordOption = (value) => {
    this.setState({ passwordOption: value, isBlocking: true });
  };

  /**
   * Close the modal and redirect to the users page.
   */
  hideModal = () => {
    this.setState({ showModal: false, redirect: true });
    // Update the list of users.
    this.props.updateUsers();
  };

  /**
   * Updates the user information.
   * @param obj
   */
  handleChange = (obj) => {
    if (Object.keys((obj))[0] === 'password') {
      this.setState({ updatedPassword: Object.values((obj))[0], isBlocking: true })
    } else {
      this.setState(prevState => ({
        user: {
          ...prevState.user,
          [Object.keys((obj))[0]]: Object.values((obj))[0]
        },
        isBlocking: true,
      }));
    }
  };

  /**
   * Update the user.
   */
  async updateUser() {
    this.setState({ updatingUser: true });

    const { user, sshKeyOption, passwordOption, updatedPassword } = this.state;

    let keys;
    // Check if the ssh key option is to upload. Let the user know if they didn't upload the key.
    if (sshKeyOption === 'upload' && user.pubSsh === '') {
      this.props.notify({ type: 'warn', msg: 'Don\'t forget to upload the public key.'});
      this.setState({ updatingUser: false });
      return;
    } else if (sshKeyOption === 'generate') { // If the user chose to generate the keys
      keys = await this.props.generateKeys();             // Generate the keys
      this.handleChange({ pubSsh: keys.data.publicKey }); // Associate the public key with the user in the state.
      user.pubSsh = keys.data.publicKey;
    }

    // Check if the user wants to generate a new password to login but forgot to either generate one or provide their own.
    if (passwordOption === 'password' && updatedPassword === '') {
      this.props.notify({ type: 'warn', msg: 'Don\'t forget to provide a password.' });
      this.setState({ updatingUser: false });
      return;
    }

    // Check if both noKey and noPassword options were chosen for login.
    if (passwordOption === 'noPassword' && sshKeyOption === 'noKey') {
      this.props.notify({ type: 'warn', msg: 'Please choose the login option.' });
      this.setState({ updatingUser: false });
      return;
    }

    // Unless the password stays the same, update the password.
    if (passwordOption !== 'keep') {
      user.password = this.state.updatedPassword;
    } else {
      delete user.password;
    }

    // Update user
    axios.put(`${api_routes.users.endpoint}/${user.username}`, user)
      .then(() => { // If success

        // Trigger private key download if the user chose to generate the key.
        if (sshKeyOption === 'generate') {
          this.setState({ isBlocking: false, updatingUser: false, showModal: true });
          utils.download(user.username, keys.data.privateKey);
        } else {
          this.setState({ isBlocking: false, updatingUser: false, redirect: true });
          // Notify about success
          this.props.notify({ msg: `User ${user.username} has been updated.`, type: 'success' });
          // Update the list of users.
          this.props.updateUsers();
        }
      })
      .catch((error) => { // If failure
        // Notify the user about the failure
        this.props.notify(handleErrorMessage(error));
        this.setState({ updatingUser: false });
        if (error.response !== undefined && error.response.status === 403) {
          this.props.logout();
        }
      });
  };

  /**
   * Delete user.
   * @param obj
   */
  deleteUser = (obj) => {
    this.props.deleteUser(obj)
      .then(() => {
        this.setState({ redirect: true });
        this.props.notify({ msg: `User ${obj.username} has been deleted.`, type: 'success' });
        this.props.updateUsers();
      })
      .catch((error) => {
        this.props.notify(handleErrorMessage(error));
        if (error.response !== undefined && error.response.status === 403) {
          this.props.logout();
        }
      });
  };

  /**
   * Cancel updating a user.
   */
  cancel = () => {
    if (this.state.isBlocking) {
      if (window.confirm('Are you sure you don\'t want to edit this user? Your changes will not be saved.')) {
        this.setState({ user: this.props.user, isBlocking: false });
      }
    } else {
      this.setState({ redirect: true });
    }
  };

  userForm = (s3Encryption, user, sshKeyOption, originalSshKey, passwordOption, usesPassword, password, updatingUser, settings, rest) => {
    if (CLOUD_PROVIDER === 'azure') {
      return (
        <AzureUserForm {...rest} handleChange={this.handleChange} handleSubmit={this.updateUser}
                       user={user} formType='edit' s3Encryption={s3Encryption} deleteUser={this.deleteUser}
                       sshKeyOption={sshKeyOption} changeSshKeyOption={this.changeSshKeyOption} settings={settings}
                       submittingForm={updatingUser} cancel={this.cancel} originalSshKey={originalSshKey}
                       passwordOption={passwordOption} changePasswordOption={this.changePasswordOption}
                       usesPassword={usesPassword} password={password} />
      );
    }
    if (CLOUD_PROVIDER === 'aws') {
      return (
        <UserForm {...rest} handleChange={this.handleChange} handleSubmit={this.updateUser}
                        user={user} formType='edit' s3Encryption={s3Encryption} deleteUser={this.deleteUser}
                        sshKeyOption={sshKeyOption} changeSshKeyOption={this.changeSshKeyOption} settings={settings}
                        submittingForm={updatingUser} cancel={this.cancel} originalSshKey={originalSshKey}
                        passwordOption={passwordOption} changePasswordOption={this.changePasswordOption}
                        usesPassword={usesPassword} password={password} />
      );
    }
  };

  render() {
    const { user, sshKeyOption, originalSshKey, passwordOption, updatedPassword, isBlocking, redirect, updatingUser, showModal } = this.state;
    const { s3Encryption, settings, ...rest } = this.props;
    const { usesPassword } = user;

    if (redirect) {
      return (
        <Redirect to={routes.users.path}/>
      );
    }

    return (
      <div>
        {user.username !== undefined ? (
          <div className='mt-4'>
            <div className='nav-breadcrumb'>
              <Link to={routes.users.path}>
                <i title='Go back' className='fas fa-long-arrow-alt-left text-primary'></i> <span className='text-primary'>Back to Users</span>
              </Link>
            </div>
            <div className='text-center'>
              <h2>Edit User</h2>
            </div>
            <div className='mt-4'>
              {this.userForm(s3Encryption, user, sshKeyOption, originalSshKey, passwordOption, usesPassword, updatedPassword, updatingUser, settings, rest)}
              <Prompt
                when={isBlocking}
                message={location =>
                  'You have unsaved changes. Are you sure you want to leave this page?'
                }
              />
            </div>
          </div>
        ) : (
          <div>
            <UserNotFound/>
          </div>
        )}
        {showModal &&
          <EditUserModal hideModal={this.hideModal} user={user} sshKeyOption={sshKeyOption} passwordOption={passwordOption} />
        }
      </div>
    );
  };
}
