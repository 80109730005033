import React  from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import matchSorter from 'match-sorter';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import './styles.css';

export default class UserAzureList extends React.Component {

  static propTypes = {
    users: PropTypes.array.isRequired,
    config: PropTypes.object.isRequired,
    deleteUser: PropTypes.func.isRequired
  };

  inputFilter = ({ filter, onChange }) => {
    return (
      <div className="input-container">
        <input type="text" value={filter ? filter.value : ''} onChange={ event => onChange(event.target.value)}
               className="input"/>
        <i className="fas fa-search input-icon"></i>
      </div>
    );
  };

  deleteUser = (username) => {
    if (window.confirm(`Are you sure you want to delete user ${username}?`)) {
      this.props.deleteUser({ username });
    }
  };

  render() {
    const { users, config } = this.props;

    const headerStyle = {
      textAlign: 'left'
    };

    const columns = [{
      Header: 'Username',
      accessor: 'username',
      headerStyle: headerStyle,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["username"] }),
      filterAll: true,
      Filter: this.inputFilter,
      maxWidth: 200,
    }, {
      Header: 'Container Name',
      accessor: 'bucketName',
      headerStyle: headerStyle,
      Cell: ({value}) => ((value) ? value : config.bucketName),
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: [ user => user.bucketName || config.bucketName ] }),
      filterAll: true,
      Filter: this.inputFilter,
      resizable: true,
    }, {
      Header: 'Upload Path',
      accessor: 'path',
      headerStyle: headerStyle,
      Cell: (row) => {
        let path = (row.value) ? row.value : `/${row.row.username}/${config.uploadPath}`;
        return path.startsWith("/") ? path : `/${path}`;
      },
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: [ user => user.path || `/${user.username}/${config.uploadPath}` ] }),
      filterAll: true,
      Filter: this.inputFilter
    }, {
      Header: 'Actions',
      accessor: 'username',
      id: 'actions-edit-delete',
      Cell: row => (
        <span>
              <span>
                <Link to={`/users/${row.value}/edit`} title="Edit user"><i className="far fa-edit edit text-primary"></i></Link>
              </span>
              <span className="remove-user" title="Delete user">
                <i className="fas fa-trash-alt delete text-danger" onClick={this.deleteUser.bind(this, row.value)}></i>
              </span>
            </span>),
      maxWidth: 110,
      filterable: false,
      sortable: false,
      style: {
        textAlign: 'center'
      }
    }];

    return (
      <ReactTable data={users} columns={columns} className="-striped -highlight bg-white mt-2" filterable resizable={false}
                  noDataText="No users found"/>
    );
  };
}
