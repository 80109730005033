import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoginForm from '../../components/Login/Form';

export default class LoginContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: 'admin',
      password: '',
    };
  };

  static propTypes = {
    login: PropTypes.func.isRequired,
    authenticating: PropTypes.bool,
  };

  handleChange = (obj) => {
    this.setState(obj);
  };

  handleSubmit = () => {
    const { username, password } = this.state;
    this.props.login({ username, password});
  };

  render() {
    const { username, password } = this.state;
    const { authenticating } = this.props;

    return (
      <LoginForm handleChange={this.handleChange} username={username} password={password}
                 handleSubmit={this.handleSubmit} submittingForm={authenticating}/>
    );
  };
}
