import React  from 'react';
import PropTypes from 'prop-types';

export default class Button extends React.Component {

  static propTypes = {
    wrapperClass: PropTypes.string,
    buttonClass: PropTypes.string,
    label: PropTypes.any,
    onClick: PropTypes.func,
    type: PropTypes.string,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    type: 'button',
    onClick: () => {
    },
  };

  handleOnClick = () => {
    this.props.onClick();
  };

  render() {
    const { wrapperClass, buttonClass, label, type, disabled } = this.props;
    return (
      <div className={wrapperClass}>
        <button type={type} className={buttonClass} onClick={this.handleOnClick} disabled={disabled}>{label}</button>
      </div>
    );
  };
}
