import React  from 'react';
import PropTypes from 'prop-types';

export default class Checkbox extends React.Component {

  static propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    checked: PropTypes.bool,
    onInputChange: PropTypes.func.isRequired,
    helpText: PropTypes.string,
    wrapperClass: PropTypes.string,
    inputWrapperClass: PropTypes.string,
    labelClass: PropTypes.string,
    checkboxLabel: PropTypes.string
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.props.onInputChange({ [this.props.name]: value });
  };

  render() {
    const { label, helpText, name, checked, inputWrapperClass, wrapperClass, labelClass, checkboxLabel } = this.props;

    return (
      <div className={wrapperClass}>
        <label className={labelClass}>{label}</label>
        <div className={`${inputWrapperClass} checkbox`}>
          <label className="cursor-pointer">
            <input type="checkbox" className={`checkbox${checked ? ' checked' : ''}`} name={name} checked={checked} onChange={this.handleInputChange} />
              <span></span>{checkboxLabel}
              <div className="overflow-hidden checkbox-help-text">
                {helpText}
              </div>
          </label>
        </div>
      </div>
    );
  };
}
