import React from 'react';
import ReactDOM from 'react-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './css/index.css';
import App from './containers/App';
import azureIntegration from "./components/Login/AzureIntegration";
//import registerServiceWorker from './registerServiceWorker';

azureIntegration.initializeAzureADIntegration();

ReactDOM.render(<App/>, document.getElementById('root'));
//registerServiceWorker();
