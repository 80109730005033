import React from 'react';
import PropTypes from 'prop-types';
import routes from '../../../routes';
import TextField from '../../General/TextField';
import Button from '../../General/Button';
import AzureIntegration from "../AzureIntegration";

const SFTPG_VERSION = process.env.REACT_APP_SFTPGATEWAY_VERSION;

export default class LoginForm extends React.Component {

  static propTypes = {
    username: PropTypes.string,
    password: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submittingForm: PropTypes.bool,
  };

  onSubmit = (ev) => {
    ev.preventDefault();
    this.props.handleSubmit();
  };

  render() {

    const { username, password, handleChange, submittingForm } = this.props;

    let azureLinks;
    if (AzureIntegration.isAzureIntegrationEnabled()) {
      azureLinks = <React.Fragment>
        <a href={AzureIntegration.getLoginUrl()}>Login with Azure SSO Credentials</a>
      </React.Fragment>
    }

    return (
      <div className="container login-page">
        <div className="row mt-5 rounded">
          <section className="col-md-6 offset-md-3">
            <div className="text-center mt-5 logo">
              <div>
                <img className="image" src="/images/sftpgateway-logo-final.png" alt="SFTPgateway logo"/>
              </div>
              <div>
                <img className="text" src="/images/sftpgateway-logo-final-text.png" alt="SFTPgateway logo"/>
              </div>
            </div>
            <div className="bg-white shadow-sm mt-5 login">
              <span className="icon"><a href={routes.kb.path} target="_blank" rel="noopener noreferrer"><i
                className="fas fa-question-circle"/></a></span>
              <form className="p-5" onSubmit={this.onSubmit}>
                <TextField wrapperClass="form-group" inputClass="form-control" label="Username" name="username"
                           onInputChange={handleChange} value={username} required disabled
                           underFieldHelpText={[<i key="1">Username can only be 'admin' in this version.</i>]}/>

                <TextField wrapperClass="form-group" inputClass="form-control" label="Password" name="password"
                           onInputChange={handleChange} value={password} required type="password"/>

                <Button type="submit"
                        label={submittingForm ? [<i key="1" className="fa fa-spinner fa-spin"></i>, " Sign In"] :
                          ["Sign In"]} disabled={submittingForm}
                        buttonClass="sign-in-btn sftpgateway-blue-btn col-12 mt-2"/>
              </form>


              <div className="text-center pb-2">
                {azureLinks}
                <br/>
                <a href={`mailto:support@thorntech.com?subject=SFTP Gateway (v. ${SFTPG_VERSION})`}
                   className="text-secondary">Contact Us</a>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}
