const routes = {
  home: { path: "/users", name: "Home" },
  users: { path: "/users", name: "Users" },
  settings: { path: "/settings", name: "Settings" },
  diagnostics: { path: "/diagnostics", name: "Diagnostics" },
  kb: { path: "http://sftpgateway.com/kb", name: "Knowledge Base" },
  twitter: { path: "https://twitter.com/sftpgateway", name: "Twitter" },
  login: { path: "/login", name: "Login" },
  logout: { path: `/logout`, name: "Logout" },
  thornTech: { path: "https://thorntech.com", name: "Thorn Technologies"},
  azureTokenCallback: { path: `/azure-callback`, name: "Azure Login Callback" },
};

export default routes;
