import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import routes from '../../../routes';

export default class Navigation extends React.Component {

  static propTypes = {
    logout: PropTypes.func.isRequired
  };

  onLogout = (e) => {
    return this.props.logout(e);
  };

  renderNavbarNav = ({ iconOnly = false }) => {
    return (
      <ul className="navbar-nav">
        <li className="nav-item">
          <NavLink className="nav-link" activeClassName="active" to={routes.users.path}>
            <i className="fas fa-users"/> {!iconOnly ? routes.users.name : ''}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" activeClassName="active" to={routes.settings.path}>
            <i className="fas fa-cog"/> {!iconOnly ? routes.settings.name : ''}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" activeClassName="active" to={routes.diagnostics.path}>
            <i className="fas fa-wrench"></i> {!iconOnly ? routes.diagnostics.name : ''}
          </NavLink>
        </li>
        <li className="nav-item">
          <a className="nav-link" target="_blank" rel="noopener noreferrer"
             href={routes.kb.path}>
            <i className="fas fa-external-link-alt"/> {!iconOnly ? routes.kb.name : ''}
          </a>
        </li>
      </ul>
    );
  };

  render() {

    return (
      <header>
        <nav className="navbar navbar-expand-lg navbar-light sticky-top bg-white">
          <a className="navbar-brand" href={routes.home.path}>
            <img src="/images/sftpgateway-logo-final.png" alt="SFTPgateway logo" className="logo"/>
            <img src="/images/sftpgateway-logo-final-text.png" alt="SFTPgateway logo" className="text"/>
          </a>
          <div className="custom-navbar-toggler">
            {this.renderNavbarNav({ iconOnly: true })}
          </div>
          <div className="collapse navbar-collapse" id="navbarNav">
            {this.renderNavbarNav({})}
          </div>
          <div className="ml-auto">
            <a className="p-2" href="/" onClick={e => {
              return this.onLogout(e);
            }}>
							<span className="custom-tooltip-bottom" data-tooltip="Sign out">
								<i className="fas fa-sign-out-alt"/>
							</span>
            </a>
          </div>
        </nav>
      </header>
    );
  };
}
