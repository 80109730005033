const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const api_routes = {
  users: { endpoint: `${BASE_URL}/api/users` },
  settings: { endpoint: `${BASE_URL}/api/configuration` },
  s3Encryption: { endpoint: `${BASE_URL}/api/s3encryption` },
  login: { endpoint: `${BASE_URL}/login` },
  ping: { endpoint: `${BASE_URL}` },
  generateKeys: { endpoint: `${BASE_URL}/api/keypair/generate` },
  passwordResetRequest: { endpoint: `${BASE_URL}/resetPasswordRequest` },
  diagnostics: { endpoint: `${BASE_URL}/api/diagnostics` },
};

export default api_routes;
