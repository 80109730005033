import React  from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import matchSorter from 'match-sorter';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import './styles.css';

export default class UserList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      keys: {
        "noEncryption": "4",
        "SSE": "1"
      }
    };
  }

  static propTypes = {
    users: PropTypes.array.isRequired,
    config: PropTypes.object.isRequired,
    deleteUser: PropTypes.func.isRequired
  };

  yesNoFilterMethod = (filter, row) => {
    if (filter.value === "all") {
      return true;
    }
    if (filter.value === "false") {
      return row[filter.id] === "";
    }
    return row[filter.id] !== "";
  };

  s3EncryptionLevelFilterMethod = (filter, row) => {
    if (filter.value === "all") {
      return true;
    }
    if (filter.value === "false") {
      return (row[filter.id] === "" || row[filter.id] === this.state.keys.noEncryption);
    }
    return (row[filter.id] !== "" && row[filter.id] !== this.state.keys.noEncryption);
  };

  yesNoFilter = ({ filter, onChange }) => {
    return (
      <select className=""
              onChange={event => onChange(event.target.value)}
              style={{ width: "95%", height: "30px" }}
              value={filter ? filter.value : "all"}
      >
        <option value="all">Show All</option>
        <option value="true">Yes</option>
        <option value="false">No</option>
      </select>
    );
  };

  inputFilter = ({ filter, onChange }) => {
    return (
      <div className="input-container">
        <input type="text" value={filter ? filter.value : ''} onChange={ event => onChange(event.target.value)}
               className="input"/>
        <i className="fas fa-search input-icon"></i>
      </div>
    );
  };

  deleteUser = (username) => {
    if (window.confirm(`Are you sure you want to delete user ${username}?`)) {
      this.props.deleteUser({ username });
    }
  };

  render() {
    const { users, config } = this.props;

    const headerStyle = {
      textAlign: 'left'
    };

    const columns = [{
          Header: 'Username',
          accessor: 'username',
          headerStyle: headerStyle,
          filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["username"] }),
          filterAll: true,
          Filter: this.inputFilter,
          maxWidth: 200,
        }, {
          Header: 'Bucket Name',
          accessor: 'bucketName',
          headerStyle: headerStyle,
          Cell: ({value}) => ((value) ? value : config.bucketName),
          filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: [ user => user.bucketName || config.bucketName ] }),
          filterAll: true,
          Filter: this.inputFilter,
          resizable: true,
        }, {
          Header: 'Upload Path',
          accessor: 'path',
          headerStyle: headerStyle,
          Cell: (row) => {
            let path = (row.value) ? row.value : `/${row.row.username}/${config.uploadPath}`;
            return path.startsWith("/") ? path : `/${path}`;
          },
          filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: [ user => user.path || `/${user.username}/${config.uploadPath}` ] }),
          filterAll: true,
          Filter: this.inputFilter
        }, {
          Header: 'Encrypted',
          accessor: 's3EncryptionLevel',
          Cell: ({ value }) => ((value && value !== this.state.keys.noEncryption) ? "Yes" : "No"),
          maxWidth: 110,
          minWidth: 105,
          filterMethod: this.s3EncryptionLevelFilterMethod,
          Filter: this.yesNoFilter,
          sortable: false,
          style: {
            textAlign: 'center'
          }
        }, {
          Header: 'Actions',
          accessor: 'username',
          id: 'actions-edit-delete',
          Cell: row => (
            <span>
              <span>
                <Link to={`/users/${row.value}/edit`} title="Edit user"><i className="far fa-edit edit text-primary"></i></Link>
              </span>
              <span className="remove-user" title="Delete user">
                <i className="fas fa-trash-alt delete text-danger" onClick={this.deleteUser.bind(this, row.value)}></i>
              </span>
            </span>),
          maxWidth: 110,
          filterable: false,
          sortable: false,
          style: {
            textAlign: 'center'
          }
        }];

    return (
      <ReactTable data={users} columns={columns} className="-striped -highlight bg-white mt-2" filterable resizable={false}
                  noDataText="No users found"/>
    );
  };
}
