import React  from 'react';
import routes from "../../../routes";

const SFTPG_VERSION = process.env.REACT_APP_SFTPGATEWAY_VERSION;

export default class Footer extends React.Component {


  render() {
    return (
      <footer>
          <div>
            <a className="" target="_blank" rel="noopener noreferrer"
               href={routes.twitter.path}>
              <i className="fab fa-twitter"/>
            </a>
          </div>
          <div className="">{`Version ${SFTPG_VERSION}`}</div>
          <div className="">Made with <span className="heart text-danger">&#9829;</span> by <a
            href={routes.thornTech.path} target="_blank" rel="noopener noreferrer">{routes.thornTech.name}</a>
          </div>

      </footer>
    );
  };
}
