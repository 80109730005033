import React  from 'react';
import PropTypes from 'prop-types';
import "./styles.css";

const DIAGNOSTICS_TAILNUMBER = process.env.REACT_APP_DIAGNOSTICS_TAILNUMBER;

export default class DiagnosticsComponent extends React.Component {

  static propTypes = {
    logFiles: PropTypes.array,
  };

  render() {

    const { logFiles } = this.props;

    return (
      <section>
        <h4>Log Files</h4>
        {logFiles.map((file, idx) => (
          <div key={idx}>
            <hr />
            <div className="logfile-name"><b>Last {DIAGNOSTICS_TAILNUMBER} Lines of {file.fileName.substring(file.fileName.lastIndexOf('/') + 1)}</b></div>
            <div className="scroll mt-2 p-2">
              {file.fileContents}
            </div>
          </div>
        ))}
      </section>
    );
  }
}
