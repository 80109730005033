import React from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import FileUploadButton from '../FileUploadButton';
import RadioButton from '../RadioButton/input';

export default class SSHKeyRadioButtons extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      placeholder: 'Upload SSH Public Key'
    }
  }

  static propTypes = {
    name: PropTypes.string.isRequired,
    changeSshKeyOption: PropTypes.func,
    onInputChange: PropTypes.func.isRequired,
    sshKeyOption: PropTypes.string,
    originalSshKey: PropTypes.string
  };

  handleOptionChange = (event) => {
    this.props.changeSshKeyOption(event.target.value);
  };

  validFileExtension = (fileName) => {
    let fileNameParts = fileName.split('.');
    if (fileNameParts[fileNameParts.length - 1] === 'key') {
      return true;
    } else if (fileNameParts[fileNameParts.length - 1] === 'pub') {
      return true;
    } else if (fileNameParts[fileNameParts.length - 1] === 'txt') {
      return true;
    } else if (fileNameParts[fileNameParts.length - 1] === 'pem') {
      return true;
    }
    return false;
  };

  handleFileUpload = (file) => {
    let allowedFileTypes = ['text/plain', 'application/x-x509-ca-cert'];
    if (file && file.type === '' && !this.validFileExtension(file.name)) {
      this.props.notify({ msg: 'Invalid file extension.', type: 'info' })
    } else if (file && file.type && !allowedFileTypes.includes(file.type)) {
      this.props.notify({ msg: `${file.type} file type is not supported.`, type: 'info' });
    } else if (file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        let contents = e.target.result;
        this.props.onInputChange({ [this.props.name]: contents });
        this.setState({ placeholder: file.name });
      };
      reader.readAsText(file);
    } else {
      this.props.notify({ msg: 'Failed to upload file.', type: 'error' });
    }
  };

  render() {
    const { placeholder } = this.state;
    const { sshKeyOption, formType, originalSshKey, ...rest } = this.props;

    return (
      <div className='form-group offset-4 col-4'>

        {(formType === 'edit' && originalSshKey !== null && originalSshKey !== "" && originalSshKey !== undefined) &&
          <RadioButton name='option' id='sshKeyOption1' label='Keep existing key'
                       checked={sshKeyOption === 'keep'} onOptionChange={this.handleOptionChange} value='keep'
                       underFieldHelpText={'User will continue to use existing SSH key.'}/>
        }

        <RadioButton name='option' id='sshKeyOption2' label='Generate new SSH key pair'
                     checked={sshKeyOption === 'generate'} onOptionChange={this.handleOptionChange} value='generate'
                     underFieldHelpText={formType === 'edit'
                       ? 'A private SSH key file will be downloaded upon saving changes to this user.'
                       : 'A private SSH key file will be downloaded upon user creation.'}/>

        <RadioButton name='option' id='sshKeyOption3' label='Upload user-provided SSH key'
                     checked={sshKeyOption === 'upload'} onOptionChange={this.handleOptionChange} value='upload'
                     underFieldHelpText={'You will be prompted to upload your SSH public key.'}/>

        <ReactCSSTransitionGroup
          transitionName='collapse'
          transitionEnterTimeout={250}
          transitionLeaveTimeout={250}>
          {(sshKeyOption === 'upload') &&
            <div>
              <FileUploadButton  {...rest} onUpload={this.handleFileUpload} wrapperClass='form-group'
                                 inputClass='form-control' placeholder={placeholder} />
            </div>
          }
        </ReactCSSTransitionGroup>

        <RadioButton name='option' id='sshKeyOption4' label='Cannot use SSH key login'
                     checked={sshKeyOption === 'noKey'} onOptionChange={this.handleOptionChange} value='noKey' />
      </div>
    );
  }
}
