import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Spinner from '../../../components/General/Spinner';
import UserList from '../../../components/User/List';
import UserAzureList from '../../../components/User/AzureList';

const CLOUD_PROVIDER = process.env.REACT_APP_CLOUD_PROVIDER;

export default class UserListContainer extends Component {

  static propTypes = {
    users: PropTypes.array.isRequired,
    updateUsers: PropTypes.func,
    loading: PropTypes.bool,
  };

  userList = (users, rest) => {
    if (CLOUD_PROVIDER === 'azure') {
      return (<UserAzureList users={users} {...rest}/>);
    }
    if (CLOUD_PROVIDER === 'aws') {
      return (<UserList users={users} {...rest}/>);
    }
  }

  render() {
    const { users, updateUsers, loading, ...rest } = this.props;
    return (
      <div className="mt-3">
        <div className="row">
          <div className="col-6">
            <h2>SFTP Users</h2>
          </div>
          <div className="col-6 pt-1 text-right">
            <Link to="/users/new">
              <button type="button" className="create-user sftpgateway-blue-btn mr-2" title="Create new user">
                <i className="fas fa-user"/>
              </button>
            </Link>
            <button type="button" className="refresh-users-list sftpgateway-light-btn" title="Refresh list" onClick={updateUsers}>
              <i className="fas fa-sync"/>
            </button>
          </div>

        </div>
        {!loading ? (
          this.userList(users, rest)
        ) : (
          <div className="text-center mt-5"><Spinner spin/></div>
        )}
      </div>
    );
  }
}
