import React from 'react';
import PropTypes from 'prop-types';

export default class RadioButton extends React.Component {

  static propTypes = {
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    onOptionChange: PropTypes.func.isRequired,
    label: PropTypes.any,
    underFieldHelpText: PropTypes.any,
    checked: PropTypes.bool,
    value: PropTypes.string.isRequired,
  };

  render() {

    const { name, id, underFieldHelpText, label, checked, onOptionChange, value } = this.props;

    return (
      <div>
        <input className="form-check-input" type="radio" name={name} id={id} value={value}
               checked={checked} onChange={onOptionChange} />
        <label className="form-check-label" htmlFor={id}>
          {label}
        </label>
        {underFieldHelpText &&
          <div className="checkbox-help-text" style={{ marginBottom: "1em" }}>
            {underFieldHelpText}
          </div>
        }
      </div>
    );
  }
}
