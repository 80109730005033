import React  from 'react';
import PropTypes from 'prop-types';

export default class SelectField extends React.Component {

  static propTypes = {
    wrapperClass: PropTypes.string,
    selectClass: PropTypes.string,
    selectWrapperClass: PropTypes.string,
    labelClass: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    helpText: PropTypes.string,
    reload: PropTypes.func,
    loading: PropTypes.bool,
    underFieldHelpText: PropTypes.any,
  };

  handleChange = (ev) => {
    this.props.onSelect({ [this.props.name]: ev.target.value });
  };

  render() {
    const { wrapperClass, selectClass, selectWrapperClass, underFieldHelpText,
      labelClass, label, value, helpText, reload, loading } = this.props;

    return (
      <div className={wrapperClass}>
        <label className={labelClass}>{label}</label>
        {helpText &&
          <span className="custom-tooltip" data-tooltip={helpText}>
            <i className="fas fa-question-circle icon"></i>
          </span>
        }
        <div className={selectWrapperClass}>
          <select className={selectClass} value={value} onChange={this.handleChange} disabled={loading}>
            {this.props.children}
          </select>
          {underFieldHelpText &&
            <div className="checkbox-help-text" style={{ marginBottom: "1em" }}>
              {underFieldHelpText}
            </div>
          }
        </div>
        {reload &&
          <span onClick={reload}>
              <i className={`fas fa-sync ${loading ? 'fa-spin' : '' } cursor-pointer icon`} title="Refresh list"></i>
          </span>
        }
      </div>
    );
  };
}
