import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';

function withNotifications(WrappedComponent) {
  return class extends Component {
    notify = ({ msg = '', type = '' }) => {
      switch (type) {
        case 'success':
          toast.success(msg);
          break;
        case 'error':
          toast.error(msg, { autoClose: 20000 });
          break;
        case 'warn':
          toast.warn(msg, { autoClose: 20000 });
          break;
        case 'info':
          toast.info(msg, { autoClose: 20000 });
          break;
        default:
          toast(msg);
      }
    };
    render() {
      return (
        <div className="h-100">
          <ToastContainer hideProgressBar autoClose={5000} />
          <WrappedComponent {...this.props} notify={this.notify}/>
        </div>
      );
    }
  }
}

export default withNotifications;