import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import TextField from '../../General/TextField';
import Button from '../../General/Button';
import Checkbox from '../../General/Checkbox';
import SSHKeyRadioButtons from '../../General/SSHKeyRadioButtons';
import PasswordRadioButtons from '../../General/PasswordRadioButtons';

export default class AzureUserForm extends React.Component {

  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    generateKeys: PropTypes.func.isRequired,
    user: PropTypes.object,
    settings: PropTypes.object,
    formType: PropTypes.string,
    deleteUser: PropTypes.func,
    cancel: PropTypes.func,
    sshKeyOption: PropTypes.string,
    passwordOption: PropTypes.string,
    usesPassword: PropTypes.bool,
    password: PropTypes.string,
    submittingForm: PropTypes.bool,
  };

  /**
   * Submit user form.
   * @param ev
   */
  onSubmit = (ev) => {
    ev.preventDefault();
    this.props.handleSubmit();
  };

  deleteUser = () => {
    if (this.props.deleteUser && this.props.user !== undefined) {
      const { username } = this.props.user;
      if (window.confirm(`Are you sure you want to delete user ${username}?`)) {
        this.props.deleteUser({ username });
      }
    }
  };

  /**
   * Handle the checkbox change (shared dir, download dir, upload dir and local dir).
   * @param obj
   */
  handleCheckboxChange = (obj) => {
    const { handleChange } = this.props;
    handleChange(obj);
  };

  render() {

    const { user, formType, handleChange, submittingForm, cancel, settings, usesPassword, password, ...rest } = this.props;

    const defaultClasses = {
      wrapperClass: "form-group row",
      labelClass: "col-4 col-sm-4 col-md-4 col-form-label text-right",
      inputClass: "form-control",
      inputWrapperClass: "col-6 col-sm-7 col-md-6 col-lg-5 col-xl-4",
      selectClass: "form-control",
      selectWrapperClass: "col-6 col-sm-7 col-md-6 col-lg-5 col-xl-4"
    };

    const helpText = {
      username: 'User will log into SFTP Gateway with this username. Usernames must be unique.',
      bucketName: 'This is the custom Blob Container to where files will be transferred. ' +
        'You may leave this blank to use the default. Edit this field to override the default Blob Container for this user.',
      path: 'This is the first folder you will see inside of your Blob Container. ' +
        'You may leave this blank to use the default. Edit to override the system default of "uploads" for this user.',
      pubSsh: 'The user will supply the private key instead of a password to login to SFTP Gateway.',
      enableUploadDir: 'This will enable a one-way uploads directory for the SFTP user that will move files to the Blob container when they are finished uploading.',
      enableLocalDir: 'This will enable a local directory for the SFTP user that will keep files on the SFTP Gateway server that will not move to cloud storage.',
      enableSharedDir: 'This will enable a shared directory on Azure Blob Storage from where all shared-enabled SFTP users will be able to download files.',
      enableDownloadDir: 'This will create a private download directory in this user\'s folder on Azure Blob Storage where you can send files for them to retrieve.',
      expirationDate: 'User will expire on this date. Expired user will be deleted along with all of their uploaded files. ' +
          'If left blank, the user will not expire.'
    };

    return (
      <form className="user-form" onSubmit={this.onSubmit}>

        <section className="user-form-section">
          <div>
            <h4>User Details</h4>
            {formType === 'edit' ? (
              <p>This is the SFTP Gateway user that you're editing.</p>
            ):(
              <p>Please enter a name for your SFTP Gateway user.</p>
            )}
          </div>
          <TextField {...defaultClasses} wrapperClass="form-group row" disabled={formType === "edit"}
                     label="Username" name="username" onInputChange={handleChange}
                     underFieldHelpText={ formType !== 'edit' ? helpText.username : '' }
                     value={user === undefined ? '' : user.username} required/>
        </section>

        <section className="user-form-section">
          <div>
            <h4>SSH Key Details</h4>
            {formType === 'edit' ? (
              <p>Users will use SSH keys to log into SFTP Gateway.
                Please select whether you would like to keep the user's existing key,
                generate a new SSH key pair, or provide your own public SSH key.</p>
            ):(
              <p>Users will use SSH keys to log into SFTP Gateway.
                Please select whether you would like to generate
                a new SSH key pair or provide your own public SSH key.</p>
            )}
          </div>
          <SSHKeyRadioButtons {...rest} onInputChange={handleChange} name="pubSsh" formType={formType} />
        </section>

        <section className="user-form-section">
          <div>
            <h4>Password Details</h4>
            {formType === 'edit' ? (
                <p>Though not recommended due to how insecure passwords can be, users can use passwords to log into SFTP Gateway.
                  Please select whether you would like to keep the user's existing password or provide a new one.</p>
            ) : (
                <p>Though not recommended due to how insecure passwords can be, users can use passwords to log into SFTP Gateway.</p>
            )}
          </div>
          <PasswordRadioButtons {...rest} onInputChange={handleChange} name="password" formType={formType}
                                password={password} usesPassword={usesPassword} />
        </section>

        <section className="user-form-section">
          <div>
            <h4>Optional User Customizations</h4>
            <p>Here is where you can customize certain aspects of your user's account.</p>
          </div>

          {(settings !== undefined && settings.enableUserExpiration !== undefined && settings.enableUserExpiration !== null
              && settings.enableUserExpiration) &&
            <TextField {...defaultClasses} type="date" wrapperClass="form-group row" label="Expiration Date"
                       name="expirationDate" onInputChange={handleChange}
                       value={user === undefined ? '' : user.expirationDate}
                       underFieldHelpText={helpText.expirationDate} min={moment().format('YYYY-MM-DD')}/>
          }

          <Checkbox {...defaultClasses} label="Enable Uploads Directory" name="enableUploadDir"
                    onInputChange={this.handleCheckboxChange}
                    checked={(user === undefined || user.enableUploadDir === undefined) ? false : user.enableUploadDir}
                    helpText={helpText.enableUploadDir} />

          <Checkbox {...defaultClasses} label="Enable Local Directory" name="enableLocalDir"
                    onInputChange={this.handleCheckboxChange}
                    checked={(user === undefined || user.enableLocalDir === undefined) ? false : user.enableLocalDir}
                    helpText={helpText.enableLocalDir} />

          <Checkbox {...defaultClasses} label="Enable Shared Directory" name="enableSharedDir"
                    onInputChange={this.handleCheckboxChange}
                    checked={(user === undefined || user.enableSharedDir === undefined) ? false : user.enableSharedDir}
                    helpText={helpText.enableSharedDir} />

          <Checkbox {...defaultClasses} label="Enable Download Directory" name="enableDownloadDir"
                    onInputChange={this.handleCheckboxChange}
                    checked={(user === undefined || user.enableDownloadDir === undefined) ? false : user.enableDownloadDir}
                    helpText={helpText.enableDownloadDir} />

          <TextField {...defaultClasses} wrapperClass="form-group row"
                     label={["Upload Destination", <br key="1" />, " Blob Container Name"]} name="bucketName"
                     onInputChange={handleChange} value={user === undefined ? '' : user.bucketName }
                     underFieldHelpText={[helpText.bucketName]}
                     placeholder="Global Default"/>

          <TextField {...defaultClasses} wrapperClass="form-group row" label="Upload Destination Prefix"
                     name="path" onInputChange={handleChange} value={user === undefined ? '' : user.path}
                     underFieldHelpText={helpText.path} placeholder="Global Default"/>

        </section>

        <section className="user-form-buttons">
          <Button type="submit" label={submittingForm ? [<i key="1" className="fa fa-spinner fa-spin"></i>, " Save"] :
            [<i key="1" className="fas fa-check"></i>, " Save"]} wrapperClass="" buttonClass="sftpgateway-blue-btn"
                  disabled={submittingForm}/>
          <Button type="button" label={[<i key="1" className="fas fa-times"></i>, " Cancel"]} buttonClass="sftpgateway-light-btn ml-2" onClick={cancel}/>
          {formType === 'edit' &&
          <Button wrapperClass="" onClick={this.deleteUser}
                  buttonClass="sftpgateway-red-btn ml-2" label={[ <i key="1" className="far fa-trash-alt"></i>, " Delete"]}/>
          }
        </section>
      </form>
    );
  };
}
