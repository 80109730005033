import React from "react";
import Modal from "../../components/General/Modal";

const CLOUD_PROVIDER = process.env.REACT_APP_CLOUD_PROVIDER;

export default class ResetPasswordModal extends React.Component {

  render() {
    const { hideModal } = this.props;

    return (
      <Modal onClose={hideModal}>
        <p>
          A default password has been generated on the server at <code>/root/default-sftpgateway-password.txt</code>.
          Click the "X" at the top right to close this modal in order to reach the login screen.
        </p>
        <p>
          You can set your password using the following instructions.
          For security reasons, this can only be done from the command line.
        </p>

        <ol>
          {CLOUD_PROVIDER === 'aws' &&
            <li>
              <p>
                SSH into your EC2 instance:
                <br/><code>ssh -i &lt;private.key&gt; ec2-user@&lt;ip-address&gt;</code>
              </p>
              <p>The private key is part of the key pair that you specified when provisioning the EC2 instance.</p>
            </li>
          }

          {CLOUD_PROVIDER === 'azure' &&
          <li>
            <p>
              SSH into your VM:
              <br/><code>ssh &lt;admin-user&gt;@&lt;ip-address&gt;</code>
            </p>
            <p>Or if you specified an SSH public key:
              <br/><code>ssh -i &lt;private.key&gt; &lt;admin-user&gt;@&lt;ip-address&gt;</code>
            </p>
          </li>
          }

          <li>
            <p>
              Reset your password:
              <br /><code>sudo resetadminpassword</code>
            </p>

          </li>

          <li>
            <p>
              Log into the admin interface:
              <br/>
              Reload this page and you should now be able to log into the admin interface using your new password.
            </p>

          </li>

        </ol>
      </Modal>
    );
  };
}
