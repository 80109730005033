import React, { Component } from 'react';
import axios from 'axios';
import { Prompt } from "react-router-dom";

import handleErrorMessage from '../../../errors';
import routes from '../../../routes';
import api_routes from '../../../api_routes';
import SystemConfigForm from '../../../components/SystemConfiguration/Form';
import SystemConfigAzureForm from '../../../components/SystemConfiguration/AzureForm';

const CLOUD_PROVIDER = process.env.REACT_APP_CLOUD_PROVIDER;

export default class SystemConfigEditContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      settings: { azureStorageName: '', azureStorageKey: '', usesAzureStorageKey: false, ...props.config },
      isBlocking: false,
      submittingForm: false,
    };
  };

  handleChange = (obj) => {
    this.setState(prevState => ({
      settings: {
        ...prevState.settings,
        [Object.keys((obj))[0]]: Object.values((obj))[0]
      },
      isBlocking: true
    }));
  };

  cancel = () => {
    if (this.state.isBlocking) {
      if (window.confirm('Are you sure you don’t want to save these changes?')) {
        this.setState({ settings: {
          azureStorageName: '', azureStorageKey: '', usesAzureStorageKey: false, ...this.props.config
          }, isBlocking: false });
      }
    }
  };

  handleSubmit = () => {
    const { settings } = this.state;

    if (!settings.usesAzureStorageKey
        && ((settings.azureStorageName !== null && settings.azureStorageName !== undefined && settings.azureStorageName !== '') && settings.azureStorageKey === '')) {
      this.props.notify({ msg: `Please supply Azure Storage Key`, type: 'warn' });
      return;
    }
    if ((settings.usesAzureStorageKey && settings.azureStorageName === '')
        || ((settings.azureStorageName === '' || settings.azureStorageName === null || settings.azureStorageName === undefined) && settings.azureStorageKey !== '')) {
      this.props.notify({ msg: `Please supply Azure Storage Name `, type: 'warn' });
      return;
    }

    this.setState({ submittingForm: true });

    axios.put(api_routes.settings.endpoint, settings)
      .then(() => {
        this.props.notify({ msg: `Default settings have been updated.`, type: 'success' });
        this.setState({ isBlocking: false, submittingForm: false });
        this.props.updateConfig();
      })
      .catch((error) => {
        this.props.notify(handleErrorMessage(error));
        this.setState({ submittingForm: false });
        if (error.response !== undefined && error.response.status === 403) {
          this.props.logout();
        }
      });
  };

  systemConfigForm = (settings, submittingForm) => {
    if (CLOUD_PROVIDER === 'azure') {
      return (
        <SystemConfigAzureForm handleChange={this.handleChange} handleSubmit={this.handleSubmit}
                        settings={settings} submittingForm={submittingForm} cancel={this.cancel}/>
      );
    }
    if (CLOUD_PROVIDER === 'aws') {
      return (
        <SystemConfigForm handleChange={this.handleChange} handleSubmit={this.handleSubmit}
                        settings={settings} submittingForm={submittingForm} cancel={this.cancel}/>
      );
    }
  };

  render() {
    const { settings, isBlocking, submittingForm } = this.state;
    return (
      <div>
        <div className="row mt-5">
          <div className="col-md-10 mx-auto">
            <p>
              Use these settings to manage some of the global configuration options for SFTP Gateway. These settings
              are a subset of the available configurations. If you would like to set advanced configuration options,
              visit the <a target="_blank" rel="noopener noreferrer" href={routes.kb.path}>
              <i className="fas fa-external-link-alt"/> {routes.kb.name}</a> or contact us
              at <a href="mailto:support@thorntech.com">support@thorntech.com</a>.
            </p>

          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-10 mx-auto">
            {this.systemConfigForm(settings, submittingForm)}
          </div>
        </div>
        <Prompt
          when={isBlocking}
          message={location =>
            'You have unsaved changes. Are you sure you want to leave this page?'
          }
        />
      </div>
    );
  };
}
