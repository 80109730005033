import React from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import * as generator from 'generate-password';
import RadioButton from '../RadioButton/input';
import TextField from '../TextField';

export default class PasswordRadioButtons extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showPassword: false
        }
    }

    static propTypes = {
        onInputChange: PropTypes.func,
        name: PropTypes.string,
        password: PropTypes.string,
        passwordOption: PropTypes.string,
        formType: PropTypes.string,
        usesPassword: PropTypes.bool
    };

    handleOptionChange = (event) => {
        this.props.changePasswordOption(event.target.value);
        if (event.target.value === 'noPassword') {
            this.props.onInputChange({ [this.props.name]: '' })
        }
    };

    togglePassword = () => {
        let showPassword = !this.state.showPassword;
        this.setState({ showPassword });
    };

    generatePassword = () => {
        let password = generator.generate({
            length: 20,
            numbers: true,
            excludeSimilarCharacters: true,
            symbols: true
        });
        this.props.onInputChange({ [this.props.name]: password });
    };

    render() {
        const { showPassword } = this.state;
        const { passwordOption, onInputChange, password, name, formType, usesPassword } = this.props;

        return (
            <div className='form-group offset-4 col-4'>

                {(formType === 'edit' && usesPassword !== undefined && usesPassword) &&
                    <RadioButton name='passwordOption' id='passwordOption0' label='Keep existing password'
                                 checked={passwordOption === 'keep'} onOptionChange={this.handleOptionChange} value='keep'
                                 underFieldHelpText={'User will continue to use existing password.'} />
                }

                <RadioButton name='passwordOption' id='passwordOption1'
                             label={formType === 'edit' && usesPassword !== undefined && usesPassword ? 'New Password' : 'Password'}
                             checked={passwordOption === 'password'} onOptionChange={this.handleOptionChange}
                             underFieldHelpText='Generate a password or provide your own.'
                             value='password' />

                <ReactCSSTransitionGroup
                    transitionName='collapse'
                    transitionEnterTimeout={250}
                    transitionLeaveTimeout={250}>
                    {passwordOption === 'password' &&
                        <div className='password-generator'>
                            <TextField wrapperClass='form-group'
                                       inputClass='form-control'
                                       name={name} type={showPassword ? 'text' :  'password'}
                                       onInputChange={onInputChange} value={password === undefined ? '' : password}
                                       underFieldHelpText={[<div key='1' className='suggest-password text-primary cursor-pointer' onClick={this.generatePassword}>Suggest Password</div>]}
                            />
                            {showPassword ?
                                (
                                    <i className='fas fa-eye-slash cursor-pointer icon' onClick={this.togglePassword}></i>
                                ) : (
                                    <i className='fas fa-eye cursor-pointer icon' onClick={this.togglePassword}></i>
                                )
                            }
                        </div>
                    }
                 </ReactCSSTransitionGroup>

                <RadioButton name='passwordOption' id='passwordOption2' label='Cannot use password login'
                             checked={passwordOption === 'noPassword'} onOptionChange={this.handleOptionChange}
                             value='noPassword' />
            </div>
        );
    }
}
