import React, { Component } from 'react';

import './styles.css';

export default class Modal extends Component {

  render() {

    const { children, onClose } = this.props;

    return (
      <div className="sftpgateway-modal-overlay">
        <div className="sftpgateway-modal">
          <div className="sftpgateway-modal-content p-5">
            {children}
          </div>
        </div>

        {onClose &&
          <button
            type="button"
            className="close-button"
            onClick={onClose}
          />
        }
      </div>
    );
  }
}
