import React, { Component } from 'react';
import DiagnosticsComponent from "../../../components/Diagnostics";
import routes from "../../../routes";
import axios from "axios";
import api_routes from "../../../api_routes";
import handleErrorMessage from "../../../errors";
import Spinner from "../../../components/General/Spinner";
import "./styles.css";

const SFTPG_VERSION = process.env.REACT_APP_SFTPGATEWAY_VERSION;

export default class DiagnosticsDetailsContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loadingDiagnostics: false,
      logFiles: [],
    };
  };

  componentDidMount() {
    // Call on the diagnostics endpoint
    this.getDiagnostics();
  }

  getDiagnostics = () => {
    this.setState({ loadingDiagnostics: true });
    axios.get(api_routes.diagnostics.endpoint)
      .then(response => {
        this.setState({ logFiles: response.data.logFiles, loadingDiagnostics: false });
      })
      .catch(error => {
        this.props.notify(handleErrorMessage(error));
        if (error.response !== undefined && error.response.status === 403) {
          this.props.logout();
        }
      });
  };

  render() {

    const { loadingDiagnostics, logFiles } = this.state;

    const emailSubject= encodeURIComponent(`SFTP Gateway (v. ${SFTPG_VERSION}) Support`);

    return (
      <div className="mt-3">
        <div className="row">
          <div className="col-6">
            <h2>{routes.diagnostics.name}</h2>
          </div>
          <div className="col-6 pt-1 text-right">
            <a href={`mailto:support@thorntech.com?subject=${emailSubject}`}>
              <button type="button" className="contact-us sftpgateway-blue-btn mr-2" title="Contact Us">
                <i className="fas fa-envelope" />
              </button>
            </a>
            <button type="button" className="refresh-diagnostics sftpgateway-light-btn" title="Refresh diagnostics" onClick={this.getDiagnostics}>
              <i className="fas fa-sync" />
            </button>
          </div>
        </div>


        <div className="row">
          <div className="col-12">
            <div className="mt-4">
              {!loadingDiagnostics ? (
                <DiagnosticsComponent logFiles={logFiles}/>
              ) : (
                <div className="text-center mt-5"><Spinner spin/></div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
}
