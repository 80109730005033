import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

export default class FileUploadButton extends React.Component {

  static propTypes = {
    buttonLabel: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    inputClass: PropTypes.string,
    wrapperClass: PropTypes.string,
    onUpload: PropTypes.func,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    buttonLabel: 'Browse',
    placeholder: 'Upload a file',
    name: 'file',
    onUpload: () => {
    }
  };

  handleUploadImage = (ev) => {
    const file = ev.target.files[0];
    this.props.onUpload(file);
  };

  render() {
    const { buttonLabel, placeholder, name, inputClass, wrapperClass, disabled } = this.props;
    return (
      <div className={wrapperClass}>
        <div className="input-file">
					<span className="button">
						<input type="file" name={name} onChange={this.handleUploadImage} disabled={disabled}/>
            { buttonLabel }
					</span>
          <input type="text" className={inputClass} placeholder={placeholder} readOnly/>
        </div>
      </div>
    );
  };
}
